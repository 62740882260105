import React, { useEffect, useState } from "react";
import { buildImageObj, publishDate, createClient } from "../../lib/helpers";
import { makeStyles } from "@material-ui/core/styles";
import Container from "../../components/container";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { Link } from "gatsby";


const useStyles = makeStyles((theme) => ({

  linkTextNotActive: {
    color: "#50586C",
    fontSize: "14px",
    fontWeight: "500",
  },
  dot:{
    marginLeft: "7px",
    marginRight: "7px",
    color: "#B2C2C3",
  },
  dotCourse:{
    color: "#3C465F",
    marginLeft: "7px",
    marginRight: "7px"
  },
  linkTextCourse:{
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#3C465F",
    textTransform: "uppercase",
  },
  linkTextActive: {
    color: "#262641",
    fontSize: "14px",
    fontWeight: "600",
  },
}));

export default function BreadCrumb(props) {

  const {
    pageBreadcrumb,
    isCoursePage
  } = props;

  const classes = useStyles();

  return (
    <Typography variant="subtitle2">
      <Link
        to={isCoursePage ? "/visible/members/" : "/"}
        style={{ textDecoration: "none" }}
        className={isCoursePage ? classes.linkTextCourse : classes.linkTextNotActive}
      >
        Home
      </Link>
      {pageBreadcrumb && pageBreadcrumb.map((bc, index) => (
        <BreadCrumbItems bc={bc} isCoursePage={isCoursePage} index={index} isLast={index === pageBreadcrumb.length -1}/>
      ))}

    </Typography>
  )
}

function BreadCrumbItems(props) {
  const classes2 = useStyles();
  const {isCoursePage, index, isLast} = props;
  const { title, linkReference } = props.bc;
  var fullLink = "/";

  if (linkReference!=null) {
    switch (linkReference._type) {

      case "information":
        fullLink = linkReference.subfolder.folder + linkReference.slug.current + '/';
        break;
      case "questionPost":
        fullLink = "/ask/" + linkReference.slug.current + '/';
        break;
      case "fundInfo":
        fullLink = "/funds/" + linkReference.slug.current + '/';
        break;
      case "people":
        fullLink = "/advisers/" + linkReference.slug.current + '/';
        break;
      case "post":
        fullLink = "/learn/articles/" + linkReference.slug.current + '/';
        break;
      case "landingPage":
        fullLink = linkReference.subfolder.folder + linkReference.slug.current + '/';
        break;
      default:
        break;
    }
  }



  return (
    <>
      <span className={isCoursePage ? classes2.dotCourse : classes2.dot}>{" • "}</span>
      <Link
        to={fullLink}
        style={{ textDecoration: "none" }}
        className={isCoursePage ? classes2.linkTextCourse : (isLast ? classes2.linkTextActive : classes2.linkTextNotActive)}
      >
        {title}
      </Link>
    </>
  );
}
