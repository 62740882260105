import { graphql } from "gatsby";
import Information from "../components/information";
import React, { useState, useEffect } from "react"
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import { getPageUrlOutOfSanityReference, toPlainText } from "../lib/helpers";
import AuthenticatorCompleteProfile from "../components/auth0/AuthenticatorCompleteProfile";
import Authenticator from "../components/auth0/Authenticator";

import { navigate } from "gatsby";
import jwtDecode from "jwt-decode";

export const query = graphql`
query InformationTemplateQuery($id: String!) {
  post: sanityInformation(id: {eq: $id}) {
    id
    _id
    publishedAt
    categories {
      _id
      title
    }
    mainImage {
      ...SanityImage
      alt
    }
    title
    company {
      noOfReviews
      wouldRecommendRating
      dashboardId
    }
    subtitle
    template
    showConfetti
    enableComments
    hideRelatedContent
    enableGatedContent
    gateStyle
    brazeCustomAttributesSB {
      ... fragSanityBrazeCustomAttribute
    }
    emailSignupWidgetTitle
    emailSignupWidgetImage {
      ...SanityImage
      alt
    }
    emailSignupWidgetParagraphText
    emailSignupWidgetButtonText
    textNextToKey
    emailSignupWidgetTextboxPlaceholder
    brazeCustomAttributes {
       ... fragSanityBrazeCustomAttribute
    }
    _rawGatedContentBody(resolveReferences: {maxDepth: 5})
    _rawGatedContentReadMoreText(resolveReferences: {maxDepth: 5})
    isPrivatePage
    theme
    hideSocialIconsTop
    hideTitle
    slug {
      current
    }
    subfolder {
      folder
    }
    _rawExcerpt(resolveReferences: {maxDepth: 5})
    _rawBody(resolveReferences: {maxDepth: 10})
    pageBreadcrumb {
      breadcrumbList {
        linkReference {
          ...fragLinkReference
        }
        title
      }
      _id
    }
    authors {
      _key
      people {
        image {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        name
      }
    }
    widgets {
      widgetType {
        title
      }
      title
      adZone
      mobileAdZone
      _rawBody
      _rawMainImage
      id
      mainImage {
        ...SanityImage
        alt
      }
      linkReference {
        ... fragLinkReference
      }
    }
    content {
      pageCount
    }
    linkMenu {
      menuLinksTop {
        text
        reference {
          AnchorName
        }
        menuChildLinks {
          text
          reference {
            AnchorName
          }
        }
      }
      menuTitle
    }
    canonicalTagOverride
  }
  allArticles: allSanityPost (sort: {order: DESC, fields: [publishedAt]},  limit: 200
     filter: {publishedAt: {ne: null, lt: "2030-01-01"}}){
        nodes {
          ... fragAllArticles
        }
  }
}
`;

const InformationTemplate = (props) => {
  const { data, errors } = props;

  const post = data && data.post;
  const getPageUrl = getPageUrlOutOfSanityReference(post)

  post.allArticles = data && data.allArticles

  var auth = true;
  if (post.isPrivatePage) {
    auth = Authenticator();
  }
  const [getToken, setToken] = useState(typeof window !== "undefined" ? localStorage.getItem("id_token") : []);
  const [getProfile, setProfile] = useState(typeof window !== "undefined" ? localStorage.getItem("profile") : []);

  const [getProfileSet, setProfileSet] = useState(null);
  const [getPage, setPage] = useState(null)

  useEffect(() => {
    const userProfile = localStorage.getItem('profile');
    const loggedIn = localStorage.getItem('id_token');

    setToken(loggedIn);

    var isProfileSet = userProfile?.toLowerCase() == "false" ? "1" : userProfile?.toLowerCase()

    localStorage.setItem("profile", isProfileSet)

    setProfileSet(isProfileSet);
    setPage(window.location.pathname);
  }, []);

  if (getProfileSet === null) {
    // You might show a loading state here while checking the authentication
    return null;
  }

  //useEffect(() => {
  //  const tok = localStorage.getItem("id_token");
  //  if (typeof window !== "undefined" && tok) {
  //    const tokenData = jwtDecode(tok);
  //    const profileVal = localStorage.getItem("profile")
  //    console.log('profileVal informationjs', profileVal)
  //    if (tokenData.email_verified && profileVal.toLowerCase() == "true") {
  //      //navigate("/members")
  //    } else if (
  //      tokenData.email_verified &&
  //      profileVal.toLowerCase() == "false"
  //    ) {

  //      var url = "/complete-profile?returnUrl=" + encodeURIComponent(window.location.pathname);

  //      navigate(url)
  //    } else {
  //      return null
  //    }
  //  }
  //}, [])


  if (auth) {
    try {
      return (
        <AuthenticatorCompleteProfile getProfileSet={getProfileSet} fromPage={getPage} getToken={getToken} privatePage={post.isPrivatePage}>
        <Layout isCoursePage={post.theme == "Boring Money Campus theme" ? true : false}>
          {errors && <SEO title="GraphQL Error" />}
          {post && (
            <SEO
              title={post.title || "Untitled"}
              description={toPlainText(post._rawExcerpt)}
              image={post.mainImage}
              canonical={!post.canonicalTagOverride && getPageUrl}
              canonicalTagOverride={post.canonicalTagOverride}
            />
          )}

          {errors && (
            <Container>
              <GraphQLErrorList errors={errors} />
            </Container>
          )}

          {post && <Information {...post} pageUrl={getPageUrl} Authenti={auth} />}
          </Layout>
        </AuthenticatorCompleteProfile>
      );
    } catch (e) {
      console.log("InformationTemplate", e);
      return (null);
    }
  }
  else {
    return null;
  }


};

export default InformationTemplate;
