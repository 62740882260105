import * as styles from "./blog-post.module.css";
import { differenceInDays, formatDistance, format } from "date-fns";
import PersonList from "./people-list";
import Container from "./container";
import PortableText from "./portableText";
import React, { useState, useEffect, useRef } from "react";
import { buildImageObj, getUserObject } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import SideBar from "./sideBar";
import AnchorMenu from "./sidebarComponents/anchorMenu";
import AllArticles from "./allArticles/allArticles";
import WidgetComponent from "./sidebarComponents/widgetComponent";
import FooterDisclaimer from "./footer-disclaimer";
import ListComments from "./comments/listComments";
import PostCommentForm from "./comments/postCommentForm";
import BreadCrumb from "./breadCrumb/breadCrumb";
import InformationGatedContentDecider from "./gatedContent/informationGatedContentDecider";
import LatestArticlesGroup from "./landingPageComponents/blocks/latestArticlesBlock";
import AuthenticatorNoRedirect from "../components/auth0/AuthenticatorNoRedirect";

import {
  AppBar,
  Grid,
  Typography,
  Toolbar,
  ListItem,
  List,
  IconButton,
  Hidden,
  Drawer,
  CssBaseline,
  Button,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "gatsby";
import { MainImage } from "gatsby-plugin-image";
import ContainerForPages from "./containerForPages";
import Confetti from 'react-confetti';
import SocialIconsWidget from "./widgets/socialIconsWidget/socialIconsWidget";
import * as uuid from 'uuid'

const useStyles = makeStyles((theme) => ({
  mainImage: {
    borderRadius: "12px",
  },
  linkTextNotActive: {
    color: "#50586C",
    fontSize: "14px",
    fontWeight: "500",
  },
  linkTextActive: {
    color: "#262641",
    fontSize: "14px",
    fontWeight: "600",
  },
  authorMargin: {
    marginTop: "31px",
  },
  dateMargin: {
    color: "#4A4A68",
  },
  grey: {
    //paddingTop: "29px",
    // paddingBottom: "30px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  newGrey: {
    [theme.breakpoints.down("md")]:{
     paddingTop: "14px",
    },
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  seventyFive: {
    width: "75%",
    "@media (max-width:600px)": {
      width: "100%",
    },
  },
  twentyFive: {
    width: "25%",
    justifyContent: "flex-end",
    "@media (max-width:600px)": {
      width: "100%",
      justifyContent: "flex-start",
    },
  },
  marginFromTitle: {
    marginTop: "24px",
    marginBottom: "0px",
  },
  dot: {
    color: "#B2C2C3",
    marginLeft: "7px",
    marginRight: "7px",
  },
  sidebar: {
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      width: "100%",
    },
  },
  content: {
    width: "calc(100% - 370px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: "20px",
    },
  },
  navMenu: {
    top: "24px",
    marginTop: "12px",
    width: "300px",
    zIndex: "22",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  adContent: {
    width: "100%",
    textAlign: "center",
  },
  titleMargin: {
    paddingBottom: "8px",
  },
  testPaddingFix: {
    paddingTop: "20px",
    [theme.breakpoints.up("md")]:{
      paddingBottom: "29px"
    },
  },
  postCommentTitle: {
    paddingTop: '1em',
    paddingBottom: '1em'
  },
  postCommentStroke: {
    height: '1px',
    width: '100%',
    background: '#DBE3EF'
  },
  postCommentParagraph: {
    paddingBottom: '1.5em'
  },
  containerForContent: {
    "& p": {
      color: "#3C465F",
    },
    "& .MuiTypography-body1, .MuiTypography-h1,  .MuiTypography-h2, .MuiTypography-h3,  .MuiTypography-h4, .MuiTypography-h5, .MuiTypography-h6": {
      "& a": {
        color: "#6464FF !important",
        textDecoration: "none",
      },
      "& a:hover": {
        textDecoration: "underline",
      },
      "& * a": {

        color: "#6464FF !important",
        textDecoration: "none",
      },
      "& * a:hover": {
        textDecoration: "underline",
      },
    },
    "& h2, h3, h4, h1, h5, h6": {
      color: "#3C465F",
    },
  },
  dotCourse: {
    color: "#3C465F",
    marginLeft: "7px",
    marginRight: "7px"
  },
  linkTextCourse: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#3C465F",
    textTransform: "uppercase",
  },
  disclaimerFlexContainer: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
  bodyContainer: {
    maxWidth: "1170px",
  },
  spacerLeft: {
    alignSelf: "flex-start",
    position: "sticky",
    top: 0,
    [theme.breakpoints.up("md")]: {
      marginLeft: "auto",
      paddingRight: "24px",
      top: "72px",
      flex: 1,
    },
  },
  spacerRight: {
    alignSelf: "flex-start",
    position: "sticky",
    top: 0,
    [theme.breakpoints.up("md")]: {
      marginRight: "auto",
      paddingLeft: "24px",
      top: "72px",
      flex: 1,
    },
  },
  breadcrumbHolder:{
    paddingTop: "14px",
    paddingBottom: "24px",
  },
  relatedContentTitle:{
    fontWeight: 600,
    fontSize: "36px",
    lineHeight: "47px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "-0.02em",
    color: "#262641",
    marginTop: "72px",
    marginBottom: "25px",
    [theme.breakpoints.down("md")]:{
      marginTop: "48px",
      marginBottom: "16px",
    }
  },
  subtitle:{
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "32px",
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    color: "#2F2F4E",
    paddingTop: "24px",
    paddingBottom: "24px",
    marginBottom: 0,
    [theme.breakpoints.down("md")]:{
      fontSize: "24px",
      fontWeight: 300,
      lineHeight: "32px",
      letterSpacing: "-0.02em",
    },
  },
  subtitleAuthor:{
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "32px",
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    color: "#2F2F4E",
    paddingTop: "24px",
    paddingBottom: "24px",
    marginBottom: 0,
    [theme.breakpoints.down("md")]:{
      fontSize: "24px",
      fontWeight: 300,
      lineHeight: "32px",
      letterSpacing: "-0.02em",
      paddingBottom: 0,
    },
  },
}));

function Information(props) {

  const {
    _rawBody,
    authors,
    authorsText,
    categories,
    title,
    subtitle,
    template,
    mainImage,
    publishedAt,
    isPrivatePage,
    widgets,
    linkMenu,
    content,
    slug,
    showConfetti,
    hideTitle,
    enableComments,
    theme,
    company,
    pageBreadcrumb,
    _id,
    enableGatedContent,
    gateStyle,
    emailSignupWidgetTitle,
    emailSignupWidgetImage,
    emailSignupWidgetParagraphText,
    textNextToKey,
    emailSignupWidgetButtonText,
    emailSignupWidgetTextboxPlaceholder,
    _rawGatedContentBody,
    _rawGatedContentReadMoreText,
    brazeCustomAttributes,
    hideSocialIconsTop,
    brazeCustomAttributesSB,
    allArticles,
    hideRelatedContent,
  } = props;

  var numReviews = "0";
  var dashId = "";


  try {
    if ((typeof company != "undefined") && (company != "") && (company != null)) {
      numReviews = company.noOfReviews;
      dashId = company.dashboardId;

    }
  } catch (e) {
    console.log("Failed on company definition", e)
  }


  let authorsTextWithMarkDefs = authorsText;
  authorsText && authorsTextWithMarkDefs.map((obj) => (obj.markDefs = []));
  var catsList = "";
  for (var i = 0; i < categories.length; i++) {
    catsList += categories[i].title;
  }
  var styleContent = styles.mainContent;
  var styleBar = styles.metaContent;
  const [breadcrumbLabel, setBreadcrumbLabel] = useState("");
  const [breadcrumbLink, setBreadcrumbLink] = useState("");
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [shouldRedirectToLoginPage, setShouldRedirectToLoginPage] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [emailCaptured, setEmailCaptured] = useState(false);

  const [shareIconsReady, setShareIconsReady] = useState(false);

  const isCoursePage = theme == "Boring Money Campus theme" ? true : false;

  const muiTheme = useTheme();
  const matchesTabletUpwards = useMediaQuery(muiTheme.breakpoints.up("md"));


  const pageTopRef = useRef(null);
  const [pageTopRefOffset, setPageTopRefOffset] = useState();
  const anchorMobileBreakpoint = useMediaQuery("(max-width: 1279px)")

  const [shouldHideRelatedContent, setShouldHideRelatedContent] = useState(false)

  const [loading, setLoading] = useState(true);

  const [widgetsReady, setWidgetsReady] = useState(false);

  var exists = false;
  var showGate = false;

  const profileVal = typeof window !== 'undefined' && localStorage.getItem("profile")

  if (typeof window !== "undefined") {
    showGate = AuthenticatorNoRedirect(brazeCustomAttributes);

  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      showGate = AuthenticatorNoRedirect(brazeCustomAttributes);
    }
    setWidgetsReady(true)
    setLoading(false);
  }, [showGate]);

  useEffect(() => {
    setTimeout(() => {
      setPageTopRefOffset(pageTopRef.current.offsetTop);
      setShareIconsReady(true);
    }, 100);
  }, [pageTopRef]);

  useEffect(() => {
    const url = window.location.href;

    if (url.includes("/learn/articles/")) {
      setBreadcrumbLabel("Articles");
      setBreadcrumbLink("/learn/articles/");
    } else if (url.includes("/learn/investing-guides/")) {
      setBreadcrumbLabel("Money guides");
      setBreadcrumbLink("/learn/investing-guides/");
    } else if (url.includes("/learn/learning-paths/")) {
      setBreadcrumbLabel("Money goals");
      setBreadcrumbLink("/learn/learning-paths/");
    }
  }, []);
  let direction;

  if (template == "C-SB") {
    styleContent = styles.contentLeft;
    styleBar = styles.sidebarRight;
    direction = "row";
  }

  if (template == "SB-C") {
    styleContent = styles.contentRight;
    styleBar = styles.sidebarLeft;
    direction = "row-reverse";
  }
  const classes = useStyles();
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    });

    //window.cardAdd();

    //window.getUserForAffiliate();

    return () => {
      window.removeEventListener("resize", () => { });
    };
  }, []);


  useEffect(() => {
    (async () => {
      window.addEventListener("storage", onStorageUpdate);

      //var token = window.localStorage.getItem("token");

      //var userObject = await getUserObject(token);

      //if (!userObject.IsAuthenticated) {
      //  if (isPrivatePage) {
      //    window.location.href = '/visible/login?returnUrl=' + encodeURIComponent(window.location.pathname + window.location.search);
      //  }
      //} else {
      //  if (isPrivatePage && !userObject.EmailConfirmed) {
      //    window.location.href = '/visible/verify-email'
      //  } else {
          setEmailConfirmed(true);
      //  }

        setIsUserAuthenticated(true);
      //}
    })();
  }, []);

  useEffect(() => {
    //if (isPrivatePage && shouldRedirectToLoginPage) {
    //  window.location.href = '/visible/login?returnUrl=' + encodeURIComponent(window.location.pathname + window.location.search);
    //}
  }, [shouldRedirectToLoginPage]);

  var onStorageUpdate = (e) => {
    const { key, newValue } = e;

    //if (key === "isUserAuthenticated") {
    //  var isAuthenticated = newValue === 'true' ? true : false;

    //  setIsUserAuthenticated(isAuthenticated);

    //  if (!isAuthenticated) {
    //    setShouldRedirectToLoginPage(true);
    //  }
    //}
  };


  //var exists = false;
  //var showGate = false;

  //if (typeof window !== "undefined") {
  //  showGate = AuthenticatorNoRedirect();

  //}

  //useEffect(() => {
  //  if (typeof window !== "undefined") {
  //    showGate = AuthenticatorNoRedirect();
  //  }
  //}, [showGate]);

  useEffect(() => {
    if (!loading){
      setTimeout(() => {
        // Get the hash from the URL (e.g., "#myAnchorTag")
        const hash = window.location.hash;

        // Check if the hash exists and points to a valid anchor tag
        if (hash) {
          // Remove the leading '#' character from the hash
          const cleanedHash = hash.slice(1);

          // Use the cleanedHash as a valid selector
          const element = document.getElementById(cleanedHash);

          if (element) {
            // Scroll to the element's position
            element.scrollIntoView({ behavior: 'instant' });
          }
        }
      })
    }
  }, [loading]);

  var privateRoute = isPrivatePage;

  if (
    (privateRoute && profileVal === "False") ||
    (privateRoute && !profileVal)
  ) {
    window.location.href = "/complete-profile"
  }

  if (loading){
    return null;
  }
  return (privateRoute && isUserAuthenticated && emailConfirmed) ||
    !privateRoute ? (
    <article className={classes.grey}>
      <ContainerForPages
        isCoursePage={theme == "Boring Money Campus theme" ? true : false}
      >
        <Grid
          container
          item
          alignItems="center"
          justify="space-between"
          className={
            theme === "Boring Money Campus theme"
              ? classes.containerForContent
              : null
          }
        >
          <Grid className={classes.spacerLeft} item xs={0} md={1}>
            {(!hideSocialIconsTop) && (!anchorMobileBreakpoint && !template) || (!hideSocialIconsTop) && (!anchorMobileBreakpoint &&
              template === "C-SB")  ? (
              shareIconsReady &&
              <SocialIconsWidget
                isSidebar={true}
                offsetTop={55}
                // offsetTop={pageTopRefOffset}
                template={template}
              />
            ) : <></>
            }
          </Grid>

          <Grid item xs={11} md={10} className={classes.bodyContainer}>
            {/*<Container customClassName={theme === "Boring Money Campus theme" ? classes.containerForContent : null}>*/}
            <Hidden smDown implementation="css">
              <div className={classes.breadcrumbHolder}>
              {pageBreadcrumb && (
                <BreadCrumb
                  pageBreadcrumb={pageBreadcrumb.breadcrumbList}
                  isCoursePage={
                    theme == "Boring Money Campus theme" ? true : false
                  }
                />
              )}

              {!pageBreadcrumb && (
                <Typography variant="subtitle2">
                  <Link
                    to="/"
                    style={{ textDecoration: "none" }}
                    className={
                      isCoursePage
                        ? classes.linkTextCourse
                        : classes.linkTextNotActive
                    }
                  >
                    Home
                  </Link>
                  {breadcrumbLabel && breadcrumbLink && (
                    <span
                      className={isCoursePage ? classes.dotCourse : classes.dot}
                    >
                      {" • "}
                    </span>
                  )}
                  {breadcrumbLabel && breadcrumbLink && (
                    <Link
                      to={breadcrumbLink}
                      style={{ textDecoration: "none" }}
                      className={
                        isCoursePage
                          ? classes.linkTextCourse
                          : classes.linkTextActive
                      }
                    >
                      {breadcrumbLabel}
                    </Link>
                  )}
                </Typography>
              )}
              </div>
            </Hidden>
            <Grid
              container
              alignItems="center"
              justify="center"
              className={classes.topAdContainer}
            >
              <Grid item className={classes.adContent}>
                {widgetsReady && <WidgetComponent
                  widgets={widgets}
                  isCoursePage={isCoursePage}
                  target="top"
                />
                }
              </Grid>
            </Grid>
            <Grid container className={classes.newGrey} direction={direction}>
              <Grid item className={classes.content}>
                <div ref={pageTopRef}></div>
                {!hideTitle && (
                  <Typography
                    variant="h1"
                    className={!authorsTextWithMarkDefs && !subtitle && classes.titleMargin}
                  >
                    {title}
                  </Typography>
                )}
                {subtitle &&
                  <Typography className={!authorsTextWithMarkDefs ? classes.subtitleAuthor : classes.subtitle} variant={"h2"}>
                    {subtitle}
                  </Typography>
                }
                {/* <p className={styles.bigtitle}>{subtitle}</p> */}
                {authorsTextWithMarkDefs && (
                  <Grid
                    container
                    item
                    alignItems="stretch"
                    justify="space-between"
                    className={classes.marginFromTitle}
                  >
                    <Grid
                      container
                      item
                      className={classes.seventyFive}
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <PortableText blocks={authorsTextWithMarkDefs} />
                    </Grid>
                  </Grid>
                )}
                {!hideSocialIconsTop && anchorMobileBreakpoint &&
                  <SocialIconsWidget />
                }
                <Grid id="confettiholder">
                  {showConfetti && (
                    <Confetti
                      recycle={false}
                      tweenDuration={7000}
                      numberOfPieces={248}
                      gravity={0.17}
                      width={width - 20}
                      height={height}
                    />
                  )}
                </Grid>

                <InformationGatedContentDecider
                    enableGatedContent={enableGatedContent}

                    emailSignupWidgetTitle={emailSignupWidgetTitle}
                    emailSignupWidgetImage={emailSignupWidgetImage}
                    emailSignupWidgetParagraphText={
                      emailSignupWidgetParagraphText
                    }
                    emailSignupWidgetButtonText={emailSignupWidgetButtonText}
                    emailSignupWidgetTextboxPlaceholder={
                      emailSignupWidgetTextboxPlaceholder
                    }
                    _rawGatedContentBody={_rawGatedContentBody}
                    _rawGatedContentReadMoreText={_rawGatedContentReadMoreText}
                    gateStyle={gateStyle}
                    _rawBody={_rawBody}
                    numberOfReviews={numReviews}
                    dashboardId={dashId}
                    brazeCustomAttributes={brazeCustomAttributes}
                    textNextToKey={textNextToKey}
                    showGate={showGate}
                />

                {content.length > 0 && (
                  <AllArticles
                    pageCount={content}
                    slugToSearch={slug.current}
                    pageCats={categories}
                  />
                )}

                {enableComments && (
                  <Grid container>
                    <div className={classes.postCommentStroke}></div>

                    <Typography
                      variant="h3"
                      className={classes.postCommentTitle}
                    >
                      Post a comment:
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.postCommentParagraph}
                    >
                      This is an open discussion and does not represent the
                      views of Boring Money. We want our communities to be
                      welcoming and helpful. Spam, personal attacks and
                      offensive language will not be tolerated. Posts may be
                      deleted and repeat offenders blocked at our discretion.
                    </Typography>
                  </Grid>
                )}

                <Grid container>
                  {enableComments && (
                    <PostCommentForm pageId={_id} isInformationPage={true} />
                  )}
                </Grid>

                <Hidden smDown implementation="js">
                  <Grid container>
                    {enableComments && (
                      <ListComments itemsPerPage={6} pageId={_id} />
                    )}
                  </Grid>
                </Hidden>

                <Hidden mdUp implementation="js">
                  <Grid container>
                    {enableComments && (
                      <ListComments itemsPerPage={4} pageId={_id} />
                    )}
                  </Grid>
                </Hidden>
              </Grid>

              <Grid item sm={0} className={classes.sidebar}>
                {/*{widgets && (
              <SideBar
                title="Sidebar"
                categoryList={catsList}
                widgets={widgets}
              />
            )} */}
                <nav className={classes.navMenu}>
                  {linkMenu[0] &&
                  _rawBody.some((item) => item._type === "anchorMenuMobile") &&
                  !matchesTabletUpwards ? (
                    <></>
                  ) : linkMenu[0] ? (
                    <AnchorMenu menuItems={linkMenu[0]} />
                  ) : (
                    <></>
                  )}

                  {widgets && widgetsReady && (
                    <WidgetComponent
                        isCoursePage={
                          theme == "Boring Money Campus theme" ? true : false
                        }
                        widgets={widgets}
                        brazeCustomAttributesSB={brazeCustomAttributesSB}
                        showGate={showGate}
                    />
                  )}
                </nav>
              </Grid>
            </Grid>
            <Grid item className={classes.adContent}>
              {widgetsReady && <WidgetComponent showGate={showGate} widgets={widgets} target="bottom" />}
            </Grid>
            {categories && !shouldHideRelatedContent && !hideRelatedContent &&
              <div>
                <Typography className={classes.relatedContentTitle} variant={"h2"}>
                  You may also like...
                </Typography>
                <LatestArticlesGroup articleCount={3} categories={categories}
                                   allArticles={allArticles} noSpacer={true}
                                     setShouldHideRelatedContent={setShouldHideRelatedContent}
                                     title={title}
                />
              </div>
              }
          </Grid>
          <Grid className={classes.spacerRight} item xs={0} md={1}>
            <Hidden smDown>
              {!hideSocialIconsTop && !anchorMobileBreakpoint &&
                template === "SB-C" && (
                  shareIconsReady &&
                  <SocialIconsWidget
                    isSidebar={true}
                    offsetTop={pageTopRefOffset}
                    template={template}
                  />
                )
              }
            </Hidden>
          </Grid>
        </Grid>
        <Grid
          className={classes.disclaimerFlexContainer}
          md={10}
          xs={11}
          item
          justify="center"
        >
          <FooterDisclaimer
            isCoursePage={theme == "Boring Money Campus theme" ? true : false}
          />
        </Grid>
      </ContainerForPages>
    </article>
  ) : (
    <></>
  );
}

export default Information;
